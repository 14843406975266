<script>

    import Select from './Select.svelte'
    import { out } from '../assets/scripts/out'
    import { fly, fade } from 'svelte/transition'
    import ArrowBanner from './ArrowBanner.svelte'
    import {stepsStore} from '../store/stepsStore'
    import { userActive, documents } from '../store/documents'

    import { close as closeSignModule } from '../assets/scripts/close'

    export let list
    export let signedInPerson
    export let baseUrl

    let steps = []

    let action = null

    const actionList = [

        {
            id: 1,
            name: 'Decline to Sign',
            action: () => openDecline = true
        },
        {
            id: 2,
            name: 'Request to sign on paper',
            action: () => openRequest = true
        }

    ]

    let openDecline = false
    let openRequest = false

    let error = ''
    let success = ''
    let loader = false
    let declineMessage = ''
    let requestMessage = ''

    $: canSign = $documents.some(x => !x.isClosed)

    const declineSign = async () => {

        loader = true

        const data = {
            reason: declineMessage
        }

        const decline = await fetch( `${baseUrl}/documents/${list[0]}/decline`, {

            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)

        })

        loader = false

        if ( decline.status === 200 ) {

            success = 'You have successfully declined to sign.'

            setTimeout(() => { 

                openDecline = false 
                success = ""

                closeSignModule()

            }, 2000)

        }

        else if ( decline.status === 404 ) {

            error = "This form is already closed."

            setTimeout(() => { 

                openDecline = false 
                error = ""

            }, 3000)

        }

        else {

            error = "Something went wrong."

            setTimeout(() => { 

                openDecline = false 
                error = ""
            
            }, 3000)

        }

    }

    const requestPaper = async () => {

        const data = {
            reason: requestMessage
        }

        loader = true

        const paper = await fetch( `${baseUrl}/documents/${list[0]}/request-paper`, {

            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)

        })

        loader = false

        if ( paper.status === 200 ) {

            success = 'Your request has been sent.'

            setTimeout(() => { 

                openRequest = false 
                success = ""

                closeSignModule()

            }, 2000)

        }

        else if ( paper.status === 404 ) {

            error = "This form is already closed."

            setTimeout(() => { 

                openRequest = false 
                error = ""

            }, 3000)

        }

        else {

            error = "Something went wrong."

            setTimeout(() => { 

                openRequest = false 
                error = ""
            
            }, 3000)

        }

    }

    stepsStore.subscribe( data => steps = data )

    $: activeStep = [...steps].find( s => !s.done ) ? [...steps].find( s => !s.done ).name : null

</script>

<style>

    .header {
        @apply w-full h-auto relative flex flex-col sticky top-0 z-30 bg-header;
    }

    .header-row {
        @apply w-full h-15 flex items-center px-4 justify-between;
    }

    .header-title {
        @apply text-xl;
    }

    .header-row-banner {
        @apply w-full flex items-center h-15 border-t-4 border-solid border-white bg-header;
    }

    .header-row-banner-fixed {
        @apply fixed left-0 top-15;
    }

    .header-row-banner-content {
        @apply h-full flex flex-grow;
    }

    .header-settings {
        @apply hidden;
    }

    .close-button {
        @apply hidden;
    }

    .close-button-mobile {
        @apply w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center cursor-pointer;
    }

    .modal {
        @apply w-full h-screen fixed top-0 left-0 z-50 bg-ch-blue bg-opacity-25 flex items-center justify-center;
    }

    .modal-form {
        @apply w-full max-w-lg h-auto p-10 bg-white shadow-xl rounded-lg grid row-gap-4;
    }

    .modal-title {
        @apply text-2xl font-medium;
    }

    .modal-success-text {
        @apply py-10 text-teal-500;
    }

    .modal-error-text {
        @apply py-10 text-ch-red;
    }

    .modal-small-text {
        @apply text-sm text-gray-600;
    }
    
    .modal-form-text {
        @apply text-sm p-4 w-full min-h-40 border border-solid border-gray-500 rounded-md outline-none;
    }

    .modal-foot {
        @apply flex justify-end;
    }

    .button-danger {
        @apply text-sm h-12 px-6 ml-4 text-white flex items-center justify-center rounded-md bg-red-500;
    }

@media (min-width: 768px) {

    .header {
        @apply h-20;

    }

    .header-row {
        @apply hidden;
    }

    .header-row-banner {
        @apply h-full border-t-0 pr-4 relative top-0;
    }

    .header-row-banner-fixed {
        @apply relative;
    }

    .header-settings {
        @apply h-30 flex items-center px-10;
    }

    .close-button {
        @apply w-10 h-10 bg-orange-600 rounded-full flex items-center justify-center cursor-pointer;
    }

    .close-button-mobile {
        @apply hidden;
    }
    
}

    *[disabled], *:disabled, *[disabled=true], *[disabled="true"] {
        opacity: 0.5;
        @apply opacity-50;
    }

</style>

<div class="header" >

    <div class="header-row" >
        
        <p class="header-title" >E-Signatures</p>

        {#if signedInPerson }

        <div class="close-button-mobile" on:click={closeSignModule} >

            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.521168 1.87906C0.146264 1.50415 0.146264 0.896315 0.521168 0.521412C0.896071 0.146509 1.50391 0.146509 1.87881 0.521412L5.99999 4.64259L10.1212 0.521412C10.4961 0.146509 11.1039 0.146509 11.4788 0.521412C11.8537 0.896315 11.8537 1.50415 11.4788 1.87906L7.35764 6.00023L11.4788 10.1214C11.8537 10.4963 11.8537 11.1042 11.4788 11.4791C11.1039 11.854 10.4961 11.854 10.1212 11.4791L5.99999 7.35788L1.87881 11.4791C1.50391 11.854 0.896071 11.854 0.521168 11.4791C0.146265 11.1042 0.146265 10.4963 0.521168 10.1214L4.64235 6.00023L0.521168 1.87906Z" fill="black"/>
            </svg>

        </div>

        {:else if activeStep == "Docs" && canSign}

        <div>

            <Select 
            
            mini={true}
            list={actionList}
            bind:value={action}
            placeholder="Options"
            
            on:select={ (e) => action = e.detail } 
            
            />

        </div>

        {/if}

    </div>

    {#if !$userActive}

    <div class="header-row-banner" class:header-row-banner-fixed={$stepsStore.find( d => d.name === 'Terms' && d.done )} >

        <div class="header-row-banner-content" >

            {#each steps as { name, display, done } } 

                <ArrowBanner name={name} displayName={display} done={done} active={activeStep} />

            {/each}

        </div>

        {#if signedInPerson }

            <div class="close-button" on:click={closeSignModule} >

                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.521168 1.87906C0.146264 1.50415 0.146264 0.896315 0.521168 0.521412C0.896071 0.146509 1.50391 0.146509 1.87881 0.521412L5.99999 4.64259L10.1212 0.521412C10.4961 0.146509 11.1039 0.146509 11.4788 0.521412C11.8537 0.896315 11.8537 1.50415 11.4788 1.87906L7.35764 6.00023L11.4788 10.1214C11.8537 10.4963 11.8537 11.1042 11.4788 11.4791C11.1039 11.854 10.4961 11.854 10.1212 11.4791L5.99999 7.35788L1.87881 11.4791C1.50391 11.854 0.896071 11.854 0.521168 11.4791C0.146265 11.1042 0.146265 10.4963 0.521168 10.1214L4.64235 6.00023L0.521168 1.87906Z" fill="white"/>
                </svg>

            </div>

        {:else if activeStep == "Docs" && canSign }

            <div class="header-settings" >

                <Select 
                
                list={actionList}
                bind:value={action}
                placeholder="Options"
                
                on:select={ (e) => action = e.detail } 
                
                />

            </div>
        
        {/if}

    </div>

    {/if}

    {#if openDecline }

        <div class="modal" in:fly out:fly >

            <div 
            
            class="modal-form" 

            use:out
            on:out={() => openDecline = false}
            
            >

                <p class="modal-title" >Decline to Sign</p>

                {#if success }

                    <p class="modal-success-text" >{success}</p>

                    {:else if error }

                    <p class="modal-error-text" >{error}</p>

                    {:else}

                    <p class="modal-small-text" >Please state your reason for declining <span class="text-xs text-red-500" >(required)</span> </p>

                    <textarea bind:value={declineMessage} class="modal-form-text" placeholder="I Decline to sign this form because ..." />

                    <div class="modal-foot" >

                        <button class="btn" on:click|preventDefault={() => openDecline = false} >Cancel</button>

                        <button 
                        
                        class="button-danger" 
                        disabled={!declineMessage}

                        on:click|preventDefault={() => declineSign()}
                        
                        >

                            Decline

                            {#if loader }

                                <svg width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#FFF" class="ml-4" >
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(1 1)" stroke-width="2">
                                            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 18 18"
                                                    to="360 18 18"
                                                    dur="1s"
                                                    repeatCount="indefinite"/>
                                            </path>
                                        </g>
                                    </g>
                                </svg>

                            {/if}

                        </button>

                    </div>

                {/if}

            </div>

        </div>

    {/if}

    {#if openRequest }

        <div class="modal" in:fly >

            <div 
            
            class="modal-form" 

            use:out
            on:out={() => openRequest = false}
            
            >

                <p class="modal-title" >Request to sign in person</p>

                <p class="modal-small-text" >The originator will be notified that you requested to sign on paper and you will not be able to sign it digitally anymore. Are you sure that you want to sign on paper?</p>

                {#if success }

                    <p class="modal-success-text" >{success}</p>

                    {:else if error }

                    <p class="modal-error-text" >{error}</p>

                    {:else}

                    <p class="modal-small-text" >Please state your reason for this request. <span class="text-xs text-red-500" >(required)</span> </p>

                    <textarea bind:value={requestMessage} class="modal-form-text" placeholder="I request this because ..." />

                    <div class="modal-foot" >

                        <button class="btn" on:click|preventDefault={() => openRequest = false} >Cancel</button>
    
                        <button 
    
                        disabled={!requestMessage}
                        on:click|preventDefault={() => requestPaper()}
                        class="button-danger" 
    
                        >
                        
                            Request

                            {#if loader }

                                <svg width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#FFF" class="ml-4" >
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(1 1)" stroke-width="2">
                                            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 18 18"
                                                    to="360 18 18"
                                                    dur="1s"
                                                    repeatCount="indefinite"/>
                                            </path>
                                        </g>
                                    </g>
                                </svg>

                            {/if}
                    
                        </button>
    
                    </div>

                {/if}

            </div>

        </div>

    {/if}

</div>

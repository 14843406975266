import { stepsStore } from './../../store/stepsStore'
import { documents, signedDocuments } from './../../store/documents'

export const close = () => {

    
    // Svelte generates styles in head, need to be removed
    // TODO: Refactor duplicate code
    document.querySelectorAll('style[id^=svelte-][id$=-style]').forEach(x=> x.remove())
    
    let element = document.getElementById("bulk-sign-wrap")
    element.parentNode.removeChild(element)
    
    documents.update( entry => entry = [] )
    signedDocuments.update( entry => entry = [] )
    stepsStore.update( steps => {
        
        let copiedSteps = [...steps]
        copiedSteps.forEach( step => step.done = false )
        
        return copiedSteps
        
    })
    
    window.dispatchEvent(new Event('chq-signature-close'))
}
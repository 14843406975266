<script>

    export let list = []
    export let baseUrl
    export let sender = {}
    export let signedInPerson

    import Header from './components/Header.svelte'
    import { stepsStore } from './store/stepsStore'
    import Terms from './views/Terms.svelte'
    import Docs from './views/Docs.svelte'
    import Sign from './views/Sign.svelte'

    let steps = []

	stepsStore.subscribe( data => steps = data )

    $: activeStep = [...steps].find( s => !s.done ) ? [...steps].find( s => !s.done ).name : null

</script>

<style global>

    @tailwind base;

    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

    :root > * {
        -webkit-overflow-scrolling: auto;
    }

    button:active { outline: none; }

    button:focus { outline: 0; }

    html,body {
        margin: 0px;
        padding: 0px;
        @apply font-body antialiased;
    }

    .hide-scroll {

        scrollbar-width: none;
    }

    .hide-scroll::-webkit-scrollbar { display: none }

    .b-regular {

        @apply text-sm h-12 px-6 bg-white flex items-center justify-center rounded-md border-2 border-solid border-ch-blue;
    }

    .b-main {

        @apply text-sm h-12 px-6 text-white flex items-center justify-center rounded-md bg-ch-blue border-2 border-solid border-ch-blue;
    }

    *[disabled], *:disabled, *[disabled=true], *[disabled="true"] {
        opacity: 0.5;
        @apply opacity-50;
    }

    /* .main {
        @apply grid grid-rows-main-small overflow-hidden;
    } */

    .main {
        @apply min-h-screen h-auto;
    }

    #modal-portal {
        @apply w-full h-full fixed top-0 left-0 z-50 pointer-events-none;
    }

@media ( min-width: 768px ) {

    .main {
        @apply grid grid-rows-main overflow-hidden;
    }

}

</style>

<main class="main" >
    <Header list={list} signedInPerson={signedInPerson} baseUrl={baseUrl} />

    <div id="modal-portal" ></div>

    {#if activeStep === 'Terms' }

    <Terms sender="{sender}" />

    {:else if activeStep === 'Docs' }

    <Docs list={list} baseUrl={baseUrl} signedInPerson={signedInPerson} />

    {:else}

    <Sign baseUrl={baseUrl} signedInPerson={signedInPerson} />

    {/if}
</main>
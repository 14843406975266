<script>

    export let sender = {}

    import { stepsStore } from '../store/stepsStore'
    import { fade } from 'svelte/transition'

    const acceptTerms = () => {

        stepsStore.update( steps => {

            let copiedSteps = [...steps]

            copiedSteps[0].done = true

            return copiedSteps

        })

    }

</script>

<div in:fade class="terms-page" >

    <div class="terms-modal-wrap" >

        <div class="terms-modal" >

            <div class="terms-modal-head" >
                
                <h1 class="terms-modal-title" >Terms and Conditions</h1>
            
            </div>

            <div class="terms-modal-body" >

                <div>

                    <h2 class="terms-modal-heading" >ELECTRONIC RECORD AND SIGNATURE DISCLOSURE</h2>

                    <p class="terms-modal-paragraph" >Please read the following information carefully. By clicking the 'I agree' button, you agree that you have reviewed the following terms and conditions and consent to transact business electronically using Clinic HQ electronic signature system. If you do not agree to these terms, do not click the 'I agree' button.</p>

                </div>

                <div>

                    <h2 class="terms-modal-heading" >Electronic documents</h2>
                    <p class="terms-modal-paragraph" >Please note that { sender.name } (<a href="mailto:{ sender.email }">{ sender.email }</a>) ("we", "us" or "Company") will send all documents electronically to you to the email address that you have given us during the course of the business relationship unless you tell us otherwise in accordance with the procedure explained herein. Once you sign a document electronically, we will send a PDF version of the document to you.</p>

                </div>

                <div>

                    <h2 class="terms-modal-heading" >Request for paper copies</h2>
                    <p class="terms-modal-paragraph" >You have the right to request from us paper copies of the documents that are sent to you electronically. You have the ability to download and print documents that are sent to you electronically. If you wish to receive paper copies of the documents that we have sent to you electronically, you can send an email to <a href="mailto:{ sender.email }">{ sender.email }</a>.</p>

                </div>

                <div>

                    <h2 class="terms-modal-heading" >Withdrawing your consent</h2>
                    <p class="terms-modal-paragraph" >At any point in time during the course of our business relationship, you have the right to withdraw your consent to receive documents in electronic format. If you wish to withdraw your consent, you can decline to sign a document that we have sent to you and send an email to <a href="mailto:{ sender.email }">{ sender.email }</a> informing us that you wish to receive documents only in paper format. Upon request from you, we will stop sending documents using Clinic HQ electronic signature system.</p>

                </div>

                <div>

                    <h2 class="terms-modal-heading" >To advise { sender.name } of your new email address</h2>
                    <p class="terms-modal-paragraph" >If you need to change the email address that you use to receive notices and disclosures from us, write to us at <a href="mailto:{ sender.email }">{ sender.email }</a>.</p>

                </div>

                <div>

                    <h2 class="terms-modal-heading" > System requirements</h2>
                    <p class="terms-modal-paragraph" >Compatible with recent versions of popular browsers such as Chrome, Firefox, Safari, and Edge.</p>

                </div>

            </div>

        </div>

    </div>

    <div class="terms-page-foot" >
        <button class="b-main space-2" on:click={acceptTerms} >I Agree</button>
    </div>

</div>

<style>
    .terms-page {
        @apply h-auto bg-gray-500;
    }

    .terms-modal-wrap {
        @apply h-auto;
    }

    .terms-modal {
        @apply h-auto max-w-screen-lg relative bg-white mx-auto tracking-widest;
    }

    .terms-modal-head {
        @apply p-6 px-10 bg-gray-200 sticky top-30;
    }

    .terms-modal-title {
        @apply text-lg;
    }

    .terms-modal-body {
        @apply pt-6 px-10 bg-white;
    }

    .terms-modal-heding {
        @apply text-lg font-medium;
    }

    .terms-modal-paragraph {
        @apply text-sm text-gray-700 py-4;
    }

    .terms-page-foot {
        @apply flex-none w-full h-20 sticky bottom-0 bg-white max-w-screen-lg mx-auto flex items-center justify-end px-6 border-t border-solid border-gray-300;
    }

    .space-2 {
        @apply ml-4;
    }

@media (min-width: 786px) {

    .terms-page {
        @apply h-full flex flex-col;
    }

    .terms-modal-wrap {
        @apply p-10 overflow-hidden;
    }

    .terms-modal {
        @apply h-full mx-auto rounded-lg overflow-auto;
    }

    .terms-modal-head {
        @apply sticky top-0;
    }

    .terms-page-foot {
        @apply px-0 bg-transparent border-t-0;
    }
}

</style>
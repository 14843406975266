<script>

    export let error

</script>

<style>

    .card-wrapper {
        @apply flex flex-col;
    }

    .card {
        @apply w-full min-w-56 h-auto border-2 border-solid border-red-800 rounded-lg overflow-hidden cursor-pointer;
    }

    .card-head {
        @apply p-4 transition-all duration-200 bg-red-400 text-white;
    }

    .card-title {
        @apply font-medium text-sm tracking-widest;
    }

    .card-foot {
        @apply flex justify-between items-center py-2 px-4 bg-gray-100 border-t border-solid border-gray-200;
    }

    .card-foot-text {
        @apply text-red-600 text-xs tracking-widest;
    }


@media (min-width: 768px) {

    .card-wrapper {
        @apply flex flex-col;
    }

    .card {
        @apply w-full min-w-72 h-auto border-2 border-solid border-red-800 rounded-lg overflow-hidden cursor-pointer;
    }

    .card-title {
        @apply text-lg;
    }

    .card-foot {
        @apply p-4;
    }

    .card-foot-text {
        @apply text-sm;
    }
}

</style>

<div class="card-wrapper" >

    <div class="card" >
        
        <div class="card-head" >

            <p class="card-title" >{ error.title }</p>

        </div>

        <div class="card-foot" >

            <p class="card-foot-text" >{ error.error }</p>

        </div>

    </div>

</div>

import { writable } from 'svelte/store'

export const stepsStore = writable([
    {
        name: 'Terms',
        display: 'Terms',
        done: false
    },
    {
        name: 'Docs',
        display: 'Sign',
        done: false
    },
    {
        name: 'Sign',
        display: 'Summary',
        done: false
    }
])

<script>

    import { documents, signedDocuments } from '../store/documents'
    import { fade } from 'svelte/transition'

    import { close as closeSignModule } from '../assets/scripts/close'

    export let baseUrl
    export let signedInPerson

</script>

<style>

    .sign-page {
        @apply h-full pt-10 overflow-y-auto;
    }

    .sign-page-content {
        @apply max-w-screen-lg mx-auto flex flex-col p-4 py-10;
    }

    .sign-page-title {
        @apply text-2xl font-bold;
    }

    .sign-page-list {
        @apply flex flex-col py-10;
    }

    .sign-page-list-top-bar {
        @apply flex px-6 py-4 bg-gray-100 rounded-lg rounded-b-none;
    }

    .top-bar-name {
        @apply text-xs flex-grow;
    }

    .top-bar-text {
        @apply text-xs;
    }

    .document {
        @apply w-full h-auto p-6 flex items-start bg-white;
    }

    .document-content {
        @apply flex-grow flex flex-col;
    }

    .document-head {
        @apply flex-grow;
    }

    .text-error {
        @apply text-xs text-red-500;
    }

    .document-links {
        @apply flex py-4;
    }

    .doc-link {
        @apply text-sm text-gray-700 whitespace-no-wrap w-auto h-10 bg-gray-200 px-4 flex items-center mr-4 rounded-lg cursor-pointer;
    }

    .doc-link:hover {
        @apply bg-gray-300;
    }

    .document:nth-child(even) {
        @apply bg-gray-100;
    }

    .doc-icon {
        @apply min-w-6 min-h-6 rounded-full flex items-center justify-center;
    }

    .doc-icon-failed {
        @apply bg-red-400;
    }

    .doc-icon-success {
        @apply bg-green-400;
    }

    .sign-page-foot {
        @apply flex justify-end px-6;
    }

@media (min-width: 768px) {

    .sign-page-content {
        @apply p-20;
    }

    .sign-page-title {
        @apply text-4xl;
    }

    .document {
        @apply items-center;
    }

    .document-content {
        @apply flex flex-row items-center;
    }

    .doc-link {
        @apply rounded-full;
    }

}

</style>

<div in:fade >

    <div class="sign-page" >

        <div class="sign-page-content" >

            <div>

                <h1 class="sign-page-title" >Signed Documents Summary</h1>

            </div>

            <div class="sign-page-list" >

                <div class="sign-page-list-top-bar" >

                    <p class="top-bar-name" >Document name</p>

                    <p class="top-bar-text" >Status</p>

                </div>

                {#each $signedDocuments as doc }
                    
                    <div class="document" >

                        <div class="document-content" >

                            <div class="document-head" >

                                <p>{ $documents.find( d => d.id === doc.id ).title }</p>

                                <p class="text-error" >{ doc.errorMessage ? doc.errorMessage : '' }</p>

                            </div>

                            <div class="document-links" >

                                <a 
                                
                                target="_blank"
                                href={`${baseUrl}/documents/${doc.id}/download`}
                                class="doc-link" 
                                
                                >
                                
                                    Download
                            
                                </a>

                                <a 
                                
                                target="_blank"
                                href={`${baseUrl}/documents/${doc.id}/certificate`}
                                class="doc-link" 
                                
                                >
                                
                                    View Certificate
                            
                                </a>

                            </div>

                        </div>

                        <div 
                        
                        class="doc-icon" 
                        class:doc-icon-success={doc.success}
                        class:doc-icon-failed={!doc.success}
                        
                        >

                            {#if doc.success }

                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.8 2.6C12.1314 2.15817 12.0418 1.53137 11.6 1.2C11.1582 0.868629 10.5314 0.958172 10.2 1.4L11.8 2.6ZM5 10L4.4 10.8C4.84183 11.1314 5.46863 11.0418 5.8 10.6L5 10ZM1.6 6.2C1.15817 5.86863 0.531371 5.95817 0.2 6.4C-0.131371 6.84183 -0.0418278 7.46863 0.4 7.8L1.6 6.2ZM10.2 1.4L4.2 9.4L5.8 10.6L11.8 2.6L10.2 1.4ZM5.6 9.2L1.6 6.2L0.4 7.8L4.4 10.8L5.6 9.2Z" fill="white"/>
                                </svg>

                            {:else}

                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.521168 1.87906C0.146264 1.50415 0.146264 0.896315 0.521168 0.521412C0.896071 0.146509 1.50391 0.146509 1.87881 0.521412L5.99999 4.64259L10.1212 0.521412C10.4961 0.146509 11.1039 0.146509 11.4788 0.521412C11.8537 0.896315 11.8537 1.50415 11.4788 1.87906L7.35764 6.00023L11.4788 10.1214C11.8537 10.4963 11.8537 11.1042 11.4788 11.4791C11.1039 11.854 10.4961 11.854 10.1212 11.4791L5.99999 7.35788L1.87881 11.4791C1.50391 11.854 0.896071 11.854 0.521168 11.4791C0.146265 11.1042 0.146265 10.4963 0.521168 10.1214L4.64235 6.00023L0.521168 1.87906Z" fill="white"/>
                                </svg>

                            {/if}

                        </div>

                    </div>

                {/each}
            </div>

            {#if signedInPerson}
                <div class="sign-page-foot" >
    
                    <button class="b-regular" on:click|preventDefault={closeSignModule} >Close</button>
    
                </div>
            {/if}
        </div>

    </div>
    
</div>
<script>

    import { createEventDispatcher } from 'svelte'
    import { out } from '../assets/scripts/out'

    const dispatch = createEventDispatcher()

    export let list = []
    export let mini = false
    export let value = null
    export let placeholder = 'Select Item'

    let openList = false

    const closeList = () => openList = false

    const selectValue = (item) => {

        value = item

        dispatch( 'select', value )

    }

</script>

<style>

    .select-wrap {
        @apply text-sm w-full relative min-w-40 h-10 flex items-center;
    }

    .select-wrap-mini {
        @apply w-10 h-10 min-w-full;
    }

    .select-head {
        @apply w-full h-full px-4 flex items-center justify-between cursor-pointer;
    }

    .select-body {
        @apply w-full min-w-56 h-auto absolute top-full right-0 z-10 p-2 bg-white flex flex-col rounded-md shadow-xl;
    }

    .select-body-item {
        @apply w-full h-10 flex items-center px-4 rounded cursor-pointer;
    }

    .select-body-item:hover {
        @apply bg-gray-100;
    }

</style>

<div 

use:out
on:out={closeList}

class="select-wrap"
class:select-wrap-mini={mini}

>

    <!-- Select Head -->

    <div class="select-head" on:click|preventDefault={ () => openList = !openList } >

        {#if !mini }

        <p>{ value ? value.name : placeholder }</p>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.4998C11.744 15.4998 11.488 15.4018 11.293 15.2068L7.29301 11.2068C6.90201 10.8158 6.90201 10.1838 7.29301 9.79276C7.68401 9.40176 8.31601 9.40176 8.70701 9.79276L12.012 13.0978L15.305 9.91776C15.704 9.53476 16.335 9.54576 16.719 9.94276C17.103 10.3398 17.092 10.9738 16.695 11.3568L12.695 15.2188C12.5 15.4068 12.25 15.4998 12 15.4998Z" fill="#082E45"/>
        </svg>

        {:else}

        <div>

            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="2" r="2" fill="#082E45"/>
            <circle cx="8" cy="8" r="2" fill="#082E45"/>
            <circle cx="8" cy="14" r="2" fill="#082E45"/>
            </svg>

        </div>

        {/if}

    </div>

    <!-- Select Body -->

    {#if openList }

    <div class="select-body" >

        {#each list as item }
            
            <div 
            
            class="select-body-item" 
            
            on:click|preventDefault={() => item.action ? [ item.action(), openList = false ] : selectValue(item)} >

                { item.name }

            </div>

        {/each}

    </div>

    {/if}

</div>
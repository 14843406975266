<script>

    export let name
    export let done
    export let active
    export let displayName

</script>

<style>

    .arrow-wrapper {
        @apply relative h-full flex flex-grow items-center justify-center;
    }

    .arrow-title {
        @apply text-xs font-medium;
    }

    .arrow-icon {
        @apply w-6 h-6 ml-6 bg-white rounded-full flex items-center justify-center;
    }

    .done {
        @apply bg-done;
    }

    .active {
        @apply bg-active text-white;
    }

    .triangle {

        width: 40px;
        height: 100%;

        position: absolute;
        top: 0px;
        right: -40px;
        z-index: 1;

        display: none;

        transform: translateX(4px);

        border-left: 20px solid white;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;

    }

    .triangle::before {

        content: "";

        width: 40px;
        height: 80px;

        position: absolute;
        left: -24px;
        top: -40px;
        z-index: 0;

        border-left: 4px solid white;
        border-top: none;
        border-bottom: none;

    }

    .triangle::after {

        content: "";

        width: 40px;
        height: 100%;

        position: absolute;
        left: -24px;
        top: -40px;
        z-index: 0;

        border-left: 20px solid #EDF1F7;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;

    }

    .active .triangle::after {

        content: "";

        width: 40px;
        height: 100%;

        position: absolute;
        left: -24px;
        top: -40px;
        z-index: 0;

        border-left: 20px solid #082E45;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;

    }

    .done .triangle::after {

        content: "";

        width: 40px;
        height: 100%;

        position: absolute;
        left: -24px;
        top: -40px;
        z-index: 0;

        border-left: 20px solid #60BAAA;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;

    }

@media ( min-width: 768px ) {

    .arrow-wrapper {
        @apply h-20 flex-grow-0 pl-10 pr-4;
    }

    .arrow-title {
        @apply text-sm;
    }

    .triangle {
        display: block;
    }

}

</style>

<div 

class:done="{ done }" 
class:active="{ active === name }"   
class="arrow-wrapper"

>

    <p class="arrow-title" >{displayName}</p>

    <div class="arrow-icon" >

        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8 2.6C12.1314 2.15817 12.0418 1.53137 11.6 1.2C11.1582 0.868629 10.5314 0.958172 10.2 1.4L11.8 2.6ZM5 10L4.4 10.8C4.84183 11.1314 5.46863 11.0418 5.8 10.6L5 10ZM1.6 6.2C1.15817 5.86863 0.531371 5.95817 0.2 6.4C-0.131371 6.84183 -0.0418278 7.46863 0.4 7.8L1.6 6.2ZM10.2 1.4L4.2 9.4L5.8 10.6L11.8 2.6L10.2 1.4ZM5.6 9.2L1.6 6.2L0.4 7.8L4.4 10.8L5.6 9.2Z" fill="#60BAAA"/>
        </svg>
            

    </div>

    <div class="triangle" />

</div>

<script>

    import { createEventDispatcher } from 'svelte'

    export let id
    export let title
    export let valid
    export let active
    export let canSign
    export let status = 3
    export let filledFields
    export let requiredFields

    const dispatch = createEventDispatcher()

    const setActiveCard = () => { dispatch( 'setActive', { id: id }) }

</script>

<style>

    .card-wrapper {
        @apply flex flex-col;
    }

    .card {
        @apply w-full min-w-56 h-auto border-2 border-solid border-gray-500 rounded-lg overflow-hidden cursor-pointer;
    }

    .card-head {
        @apply p-4 transition-all duration-200;
    }

    .card-title {
        @apply font-medium text-sm tracking-widest;
    }

    .card-foot {
        @apply flex justify-between items-center py-2 px-4 bg-gray-100 border-t border-solid border-gray-200;
    }

    .card-foot-text {
        @apply text-xs tracking-widest;
    }

    .card-apply {
        @apply w-full h-auto py-2 bg-white border-2 border-solid border-active rounded-lg overflow-hidden cursor-pointer flex items-center justify-center;
    }

    .card-apply:hover {
        @apply bg-ch-light-blue bg-opacity-25;
    }

    .card-apply-text {
        @apply text-xs tracking-widest;
    }

    .card-spliter {
        @apply w-full h-0 border-b-0 border-solid border-gray-300;
    }

    .active-card {
        @apply bg-active text-white;
    }

    .signed-card {
        @apply bg-done text-white;
    }

    .declined-card {
        @apply bg-red-400 text-white;
    }

    .valid-card {
        @apply bg-done text-white;
    }

    .active-card-border {
        @apply border-active;
    }

@media (min-width: 768px) {

    .card-wrapper {
        @apply flex flex-col;
    }

    .card {
        @apply w-full min-w-72 h-auto border-2 border-solid border-gray-500 rounded-lg overflow-hidden cursor-pointer;
    }

    .card-title {
        @apply text-lg;
    }

    .card-foot {
        @apply p-4;
    }

    .card-foot-text {
        @apply text-sm;
    }

    .card-foot-text-green {
        @apply text-green-400;
    }

    .card-apply {
        @apply py-4;
    }

    .card-apply-text {
        @apply text-sm;
    }

    .card-spliter {
        @apply border-b-2;
    }

}

</style>

<div class="card-wrapper" >

    <div 
    
    on:click={setActiveCard} 
    class:active-card-border={active} 
    class="card" >
        
        <div 
        
        class="card-head"
        class:valid-card={valid}
        class:active-card={active}
        class:signed-card={status === 3}
        class:declined-card={status === 4 || status === 6}

        >

            <p class="card-title" >{title}</p>

        </div>

        <div class="card-foot" >

            <p class="card-foot-text" >Required Fields</p>

            <p class="card-foot-text" ><span class="card-foot-text-green" >{filledFields}</span> / {requiredFields}</p>

        </div>

    </div>

    {#if active && valid && !canSign }

    <div 
    
    on:click={() => dispatch('copy')}
    class="card-apply" 
    
    >

        <p class="card-apply-text" >Apply Fields To All Forms</p>

    </div>

    <div class="card-spliter" ></div>

    {/if}

</div>

<script>
  
  import { onMount } from "svelte"

  export let page
  export let action

  onMount(async() => {
    if ( action ) await action(page)
  })

</script>

<canvas id={page.id} width={page.canvasWidth} height={page.canvasHeight} style="width: 100%; height: 100%;" />
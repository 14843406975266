import Main from './Main.svelte';

// const app = new Main({
// 	target: document.body
// })

// export default app

export default (list, baseUrl, sender, signedInPerson, isInline) => {

	const elem = document.createElement('div')
	elem.setAttribute('id', 'bulk-sign-wrap')

	let vh = window.innerHeight * 0.01

	if (!isInline) {

		document.documentElement.style.setProperty('--vh', `${vh}px`)

		elem.style.cssText = `
			position:fixed;
			left:0px;
			top:0px;
			width:100%;
			height: 100vh;
  			height: calc(var(--vh, 1vh) * 100);
			z-index:100;
			background:white;
			overflow:auto;
		`
		window.addEventListener('resize', setHeight)
	}

	document.body.appendChild(elem)

	if (signedInPerson) {
		document.body.style.overflow = 'hidden'
	}

	let mainComponent = new Main({
		target: elem,
		props: {
			list: list,
			baseUrl: baseUrl,
			sender: sender,
			signedInPerson: signedInPerson
		}
	})

	window.addEventListener('chq-signature-close', destroyMain);

	function setHeight() {

		vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)

	}

	function destroyMain() {

		mainComponent.$destroy()

		window.removeEventListener('resize', setHeight)
		window.removeEventListener('chq-signature-close', destroyMain)

		if (signedInPerson) {
			document.body.style.overflow = null
		}
	}
}